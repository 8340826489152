import { Box } from '@mui/material'
import ExtraInfoGuestActions from '~/src/components/auth/extra-info/guest/ExtraInfoGuestActions'
import ExtraInfoGuestFields from '~/src/components/auth/extra-info/guest/ExtraInfoGuestFields'
import useLoginAsGuest from '~/src/hooks/auth/useLoginAsGuest'
import { GuestFormInterface } from '~/src/types/forms/GuestFormInterface'
import { FormikProps, useFormik } from 'formik'
import useGuestForm from '~/src/hooks/forms/guest-form/useGuestForm'

const ExtraInfoGuestForm = () => {
  const { login: loginAsGuest, loading } = useLoginAsGuest()
  const { initialValues, validationSchema } = useGuestForm()
  const formik: FormikProps<GuestFormInterface> = useFormik<GuestFormInterface>({
    initialValues,
    validationSchema,
    onSubmit: (values: GuestFormInterface) => loginAsGuest(values)
  })

  return (
    <Box
      component='form'
      onSubmit={formik.handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 5
      }}
    >
      <ExtraInfoGuestFields
        form={formik}
      />

      <ExtraInfoGuestActions
        form={formik}
        loading={loading}
      />
    </Box>
  )
}

export default ExtraInfoGuestForm
