import { useTranslations } from '~/src/hooks/useTranslations'
import { GuestFormInterface } from '~/src/types/forms/GuestFormInterface'
import { FormikProps } from 'formik'
import FormikTextField from '~/src/components/mui-wrappers/fields/FormikTextField'
import PhoneInput from '~/src/components/mui-wrappers/styled/phone-input'
import { Box } from '@mui/material'
import useFormFields from '~/src/hooks/forms/useFormFields'
import { GUEST_FORM_FIELDS_STATE } from '~/src/stores/guest-form'
import { useEoValue } from '~/src/hooks/useEoState'

interface Props {
  form: FormikProps<GuestFormInterface>,
}

const ExtraInfoGuestFields = ({ form }: Props) => {
  const { t } = useTranslations()
  const formFields = useEoValue(GUEST_FORM_FIELDS_STATE)
  const { isHidden, isRequired } = useFormFields(formFields)

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 2
    }}>
      <FormikTextField
        hidden={isHidden('email')}
        TextFieldProps={{
          form,
          fullWidth: true,
          color: 'content',
          name: 'email',
          type: 'email',
          inputMode: 'email',
          variant: 'outlined',
          required: isRequired('email'),
          label: t('form.email')
        }}
      />

      <FormikTextField
        hidden={isHidden('name')}
        TextFieldProps={{
          form,
          fullWidth: true,
          color: 'content',
          name: 'firstName',
          variant: 'outlined',
          required: isRequired('name'),
          label: t('form.firstname')
        }}
      />

      <FormikTextField
        hidden={isHidden('name')}
        TextFieldProps={{
          form,
          fullWidth: true,
          color: 'content',
          name: 'lastName',
          variant: 'outlined',
          required: isRequired('name'),
          label: t('form.lastname')
        }}
      />

      <PhoneInput
        hidden={isHidden('phone')}
        PhoneInputProps={{
          form,
          fullWidth: true,
          color: 'content',
          name: 'phone',
          label: t('form.phone'),
          required: isRequired('phone'),
          variant: 'outlined'
        }}
      />

      <FormikTextField
        hidden={isHidden('company')}
        TextFieldProps={{
          form,
          fullWidth: true,
          color: 'content',
          name: 'society',
          variant: 'outlined',
          required: isRequired('company'),
          label: t('form.society')
        }}
      />
    </Box>
  )
}

export default ExtraInfoGuestFields

