import { Container } from '@mui/material'
import ExtraInfoGuest from '~/src/components/auth/extra-info/guest/ExtraInfoGuest'
import useGetExtraInfoGuestTemplate from '~/src/pages/extra-info/guest/styles/useGetExtraInfoGuestTemplate'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import ContentContainer from '~/src/components/utils/layout/ContentContainer'
import RoutesEnum from '~/src/router/enums/routes.enum'
import SlideFromRight from '~/src/components/mui-wrappers/animations/motion/SlideFromRight'

const ExtraInfoGuestPage = () => {
  const pageName: keyof typeof RoutesEnum = 'EXTRA_INFO_GUEST'
  const styles = useGetExtraInfoGuestTemplate()

  return (
    <SlideFromRight
      pathname={pageName}
      transition={{
        duration: .5
      }}
    >
      <DocumentTitleInjector pageName={pageName}/>
      <Container sx={styles?.getPageSx()}>
        <ContentContainer>
          <ExtraInfoGuest />
        </ContentContainer>
      </Container>
    </SlideFromRight>
  )
}

export default ExtraInfoGuestPage
