import getExtraInfoGuestTemplateService
  from '~/src/pages/extra-info/guest/styles/GetExtraInfoGuestTemplateService'
import StylesExtraInfoGuestServiceInterface
  from '~/src/pages/extra-info/guest/styles/StylesExtraInfoGuestServiceInterface'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useLayout } from '~/src/hooks/layout/useLayout'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const useGetExtraInfoGuestTemplate = (): StylesExtraInfoGuestServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('extraInfoGuest')

  useLayout('extraInfoGuest')

  return getExtraInfoGuestTemplateService(pageStyle, muiTheme)
}

export default useGetExtraInfoGuestTemplate
