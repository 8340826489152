import Button from '~/src/components/mui-wrappers/buttons/Button'
import { useTranslations } from '~/src/hooks/useTranslations'
import { FormikProps } from 'formik'
import { GuestFormInterface } from '~/src/types/forms/GuestFormInterface'

interface Props {
  form: FormikProps<GuestFormInterface>,
  loading: boolean,
}

const ExtraInfoGuestActions = ({ form, loading }: Props) => {
  const { t } = useTranslations()

  return (
    <Button
      disabled={!form.isValid}
      loading={loading}
      variant='contained'
      type='submit'
      fullWidth
    >
      {t('actions.next')}
    </Button>
  )
}

export default ExtraInfoGuestActions
